
<template>
  <div class="item-box">
    <div class="tag"></div>
    <div class="top">
      <span class="title">历史警报</span>
    </div>
    <swiper
      :watchSlidesProgress="true"
      :watchSlidesVisibility="true"
      :slidesPerView="3"
      class="mySwiper"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
      :direction="'vertical'"
      :touchAngle='180'
      :loop="true"
      :loopFillGroupWithBlank="false"
      :height="252"
    >
      <swiper-slide v-for="item in messageList" :key="item.id">
        <div class="item" @click="show(item)">
          <div class="item-top">
            <div class="item-top-left">
              <span
                class="iconfont"
                :class="
                  item.warnLevel == 1
                    ? 'warning-yellow'
                    : item.warnLevel === 2
                    ? '  warning-red'
                    : ''
                "
                >&#xe69f;</span
              >
              <span
                :class="
                  item.warnLevel == 1
                    ? 'warning-yellow'
                    : item.warnLevel === 2
                    ? '  warning-red'
                    : ''
                "
                >{{
                  item.warnLevel === 1
                    ? "黄色预警"
                    : item.warnLevel === 2
                    ? "红色警报"
                    : "警报信息"
                }}</span
              >
            </div>
            <span>{{ item.createTime }}</span>
          </div>
          <div class="content">
            {{ item.content }}
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/swiper.scss'
// import Swiper core and required modules
import SwiperCore, { Autoplay } from 'swiper/core'

import { getInfoByDeviceIdAndNumber } from '@/api/home'

// install Swiper modules
SwiperCore.use([Autoplay])
export default {
  emits: ['showDetails'],
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      autoplay: false,
      transform: ''
    }
  },
  props: ['messageList'],
  computed: {},
  created () {},
  methods: {
    show (item) {
      getInfoByDeviceIdAndNumber({
        deviceNumber: item.deviceNumber,
        deviceId: item.deviceId,
        type: 2
      }).then((res) => {
        // 单位
        var unit = res.data.unit

        // 房名
        item.roomName = res.data.laboratoryName + res.data.roomName
        // 当前值
        item.nowValue = res.data.value + unit
        // 恢复值
        item.recoveryValue1 = item.recoveryValue + unit
        item.name = res.data.name
        // 电力系统状态
        item.rstate = res.data.rstate
        // 是否异常
        item.isRed = false
        if (((res.data.warnMinValue != null && res.data.value <= res.data.warnMinValue) || (res.data.warnMaxValue != null && res.data.value >= res.data.warnMaxValue))) {
          item.isRed = true
        }
        // 警报类型
        item.type = res.data.type

        if (item.type === 3 && res.data.value === 1) {
          item.value = res.data.value
          item.isRed = true
        }
        // 阈值

        if (res.data.warnMinValue != null && res.data.warnMaxValue == null) {
          item.limitValue = `x${
                res.data.warnMinValue != null ? ' ＞ ' + res.data.warnMinValue : ''
              }${unit}`
        } else {
          item.limitValue = `${res.data.warnMinValue != null ? res.data.warnMinValue + ' ＜ ' : ''}x${
                res.data.warnMaxValue != null ? ' ＜ ' + res.data.warnMaxValue : ''
              }${unit}`
        }

        // 警报数值
        item.value1 = item.value + unit
        item.tcControlDeviceList = res.data.tcControlDeviceList
        item.tcControlDeviceMap = res.data.tcControlDeviceMap

        this.$emit('showDetails', item)
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.item-box {
  width: 403px;
  height: 312px;
  background: rgba($color: #000000, $alpha: 0.4);
  border-radius: 16px;
  border: 2px solid rgba(55, 255, 177, 0.5);
  padding: 20px;
  padding-bottom: 0;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  .tag {
    width: 6px;
    height: 12px;
    background: #37ffb1;
    border-radius: 0px 4px 4px 0px;
    opacity: 0.3;
    position: absolute;
    left: 0;
    top: 25px;
  }
  .top {
    margin-bottom: 12px;
    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #37ffb1;
    }
  }

  .item {
    background: rgba($color: #ffffff, $alpha: 0.1);
    border-radius: 8px;
    padding: 4px 10px;
    color: #e8fffb;
    height: 76px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .item-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      .item-top-left {
        display: flex;
        align-items: center;
        color: #37ffb1;
        .iconfont {
          font-size: 16px;
          margin-right: 4px;
        }
        .warning-red {
          color: #ff003f;
        }
        .warning-yellow {
          color: #ff7700;
        }
      }
    }
    .content {
      font-size: 13px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}
</style>
