
<template>
  <div class="top">
    <div class="left">
      <img class="logo" src="../assets/logo.png" alt="" />
      <span class="title" @click="clickFullscreen">智慧实验室控制中心</span>
    </div>
    <div class="right">
      <span class="time">{{ time }}</span>
      <div class="date-box">
        <span class="week">{{ week }}</span>
        <span class="date">{{ date }}</span>
      </div>
      <span class="iconfont close" :style="status===1?'background:rgba(131 ,243, 131,0.3)':''" @click="relayControlBypwd">&#xe69a;</span>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'

import { getTime, getDeviceStatus, relayControlBypwd } from '@/api/home'
export default {
  components: {},
  data () {
    return {
      time: '',
      week: '',
      date: '',
      Fullscreen: false,
      status: 0
    }
  },

  mounted () {
    getTime().then((res) => {
      this.cTime(res.data)
    })
    this.getDeviceStatus()
  },
  props: ['openId'],

  methods: {
    getDeviceStatus () {
      getDeviceStatus({ deviceId: 1 }).then(res => {
        if (Number(res.code) === 200) {
          this.status = res.data
        }
      })
    },
    relayControlBypwd () {
      var state = null
      var t1 = '开启后实验室所有电力供应将接通，请谨慎操作！'
      var t2 = '是否开启电力系统'
      if (this.status === 0) {
        state = 1
      } else {
        t1 = '关闭后实验室所有电力供应将切断，请谨慎操作！'
        t2 = '是否关闭电力系统'
        state = 0
      }
      this.$prompt(t1, t2, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入密码'
      })
        .then(({ value }) => {
          relayControlBypwd({ state, deviceIds: [1], openId: this.openId, passWord: value }).then(res => {
            console.log(Number(res.code))
            if (Number(res.code) === 200) {
              this.status = res.data
            } else {
              this.$message({
                type: 'info',
                message: res.msg
              })
            }
          }).catch(err => {
            console.log(err)
          })
        })
        .catch(() => {

        })
    },
    cTime (timeS) {
      var myDate = new Date(timeS)
      var Week = ['日', '一', '二', '三', '四', '五', '六']
      var y = myDate.getFullYear() // 获取完整的年份(4位,1970-????)
      var m = myDate.getMonth() + 1 // 获取当前月份(0-11,0代表1月)
      var d = myDate.getDate() // 获取当前日(1-31)
      var w = myDate.getDay() // 获取当前星期X(0-6,0代表星期天)
      var hh = myDate.getHours() // 获取当前小时数(0-23)
      var mm = myDate.getMinutes() // 获取当前分钟数(0-59)
      var ss = myDate.getSeconds() //
      m = m < 10 ? '0' + m : m
      d = d < 10 ? '0' + d : d
      hh = hh < 10 ? '0' + hh : hh
      mm = mm < 10 ? '0' + mm : mm
      this.date = y + '年' + m + '月' + d + '日'
      this.week = '星期' + Week[w]
      this.time = hh + ':' + mm
      var timer = setInterval(() => {
        timeS += 1000
        ss++
        if (ss === 60) {
          ss = 0
          clearInterval(timer)
          this.cTime(timeS)
        }
      }, 1000)
    },
    clickFullscreen () {
      relayControlBypwd()
      if (!screenfull.isEnabled) {
        this.$message({
          message: 'you browser can not work',
          type: 'warning'
        })
        return false
      }
      // this.Fullscreen = true
      screenfull.toggle()
    }
  }
}
</script>
<style lang='scss' scoped>
.top {
  height: 72px;
  box-shadow: 0px 4px 8px 0px rgba(32, 37, 52, 0.08),
    inset 0px 12px 11px 0px rgba(38, 255, 240, 0.2),
    inset 0px -7px 15px 0px rgba(38, 255, 240, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  .left {
    font-size: 28px;
    font-weight: 500;
    color: #e8fffb;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    .logo {
      width: 122px;
      height: 40px;
      margin-right: 10px;
    }
    .title {
      font-weight: 600;
      line-height: 28px;
      padding-top: 6px;
    }
  }
  .right {
    font-weight: 400;
    color: #e8fffb;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    .time {
      font-size: 24px;
      padding: 0 16px;
      position: relative;
      &::after {
        content: "";
        width: 1px;
        height: 20px;
        background: #e8fffb;
        position: absolute;
        right: 0;
        top: 5px;
      }
    }
    .date-box {
      display: flex;
      flex-direction: column;
      padding: 0 24px 0 16px;
      font-size: 14px;
      color: #cdfffc;
    }
    .close {
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      font-size: 24px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
