import Vue from 'vue'
import axios from 'axios'
// import store from '@/store'
import {
  Message
} from 'element-plus'

const baseURL = process.env.VUE_APP_API_BASE_URL

// 创建 axios 实例
const service = axios.create({
  baseURL: baseURL, // api base_url
  timeout: 15000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = Vue.ls.get('ACCESS_TOKEN')
    if (error.response.code === 403) {
      Message.error(data.message)
    }
    if (error.response.code === 401 && !(data.result && data.result.isLogin)) {
      Message.error('Authorization verification failed')

      if (token) {
        // store.dispatch('Logout').then(() => {
        //   setTimeout(() => {
        //     window.location.reload()
        //   }, 1500)
        // })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  // const token = Vue.ls.get('ACCESS_TOKEN')
  // const currentRoute = Vue.ls.get('SET_CUT_ROUTE')
  // if (token) {
  //   config.headers.token = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  // }
  config.headers['Login-Type'] = 'PC'
  // config.headers.Cut_Route = currentRoute
  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  // const data = response.data
  return response.data
  // if (Number(data.code) === 401) {
  //   console.log(data)
  //   const token = Vue.ls.get('ACCESS_TOKEN')

  //   if (token) {
  //     store.dispatch('user/logout').finally(() => {
  //       setTimeout(() => {
  //         window.location.reload()
  //       }, 1500)
  //     })
  //   }
  // } else {
  //   return response.data
  // }
}, err)

export default service
