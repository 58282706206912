
<template>
  <div class="item-box">
    <div class="tag"></div>
    <div class="top">
      <span class="title" @click="play">监控系统</span>
    </div>
    <div class="list" v-if="list.length > 0">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        :class="(isShowed && index === 0) ? 'arrow_box' : ''"
      >
        <video class="video-js" :id="'video-webrtc' + index" controls></video>
        <div class="mask" @click="requestFullScreen(index)"></div>
        <span class="name">{{ item.deviceName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'

import { getVideoInfo } from '@/api/home'
export default {
  components: {},
  data () {
    return {
      list: [],
      src: '',

      isShowed: false
    }
  },
  created () {},
  mounted () {
    var ua = navigator.userAgent.toLowerCase()
    var isWeixin = ua.indexOf('micromessenger') !== -1
    // var isAndroid = ua.indexOf('android') != -1
    // var isIos = ua.indexOf('iphone') !== -1 || ua.indexOf('ipad') !== -1
    if (isWeixin) {
      wx.config({
        debug: false,
        appId: 'wxf2a20ee4793b5bf8',
        timestamp: '',
        nonceStr: '',
        signature: '',
        jsApiList: []
      })
      wx.ready(() => {
        this.getVideoInfo()
      })
    } else {
      this.getVideoInfo()
    }
  },

  methods: {
    showFull (showVideo) {
      this.isShowed = showVideo
    },
    requestFullScreen (index) {
      this.$nextTick(() => {
        var video = document.getElementById('video-webrtc' + index)
        if (video.requestFullscreen) {
          video.requestFullscreen()
        } else if (video.mozRequestFullScreen) {
          video.mozRequestFullScreen()
        } else if (video.msRequestFullscreen) {
          video.msRequestFullscreen()
        } else if (video.oRequestFullscreen) {
          video.oRequestFullscreen()
        } else if (video.webkitRequestFullscreen) {
          video.webkitRequestFullScreen()
        } else if (video.webkitSupportsFullscreen) {
          video.webkitEnterFullscreen()
        }
      })
    },
    getVideoInfo () {
      getVideoInfo({ labId: 1 }).then((res) => {
        this.list = res.data
        this.$nextTick(() => {
          this.getVideo()
        })
      })
    },
    getVideo () {
      for (let index = 0; index < 4; index++) {
        var video = document.getElementById('video-webrtc' + index)
        var url = this.list[index].streamUrl
        // eslint-disable-next-line no-undef
        var player = new JSWebrtc.Player(url, {
          'x5-video-player-fullscreen': true,
          'x5-playsinline': true,
          playsinline: true,
          'webkit-playsinline': true,
          video: video,
          autoplay: true,
          onPlay: (obj) => {
            // console.log('start play')
          }
        })
        console.log(player)
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.arrow_box {
  animation: glow 800ms ease-out infinite alternate;
  //  border-radius: 6px;
  border: 1px solid #e6192f;
}
@keyframes glow {
  0% {
    border-color: #e6192f;
    box-shadow: 0px 0px 20px rgba(251, 6 ,6, 0.5)
  }
  100% {
    border-color: #e6192f;
    box-shadow: 0px 0px 20px #e6192f
  }
}

.my-video-dimensions {
  width: 100%;
  height: 100%;
  border: 1px solid;
  overflow: hidden;
}
/deep/ .vjs-loading-spinner {
  margin: 0;
  transform: translate(50%, 50%);
  margin-left: 40px;
}
.item-box {
  width: 404px;
  height: 312px;
  background: rgba($color: #000000, $alpha: 0.4);
  border-radius: 16px;
  border: 2px solid rgba(55, 255, 177, 0.5);
  padding: 20px;
  box-sizing: border-box;
  // opacity: 0.7;
  position: relative;
  .tag {
    width: 6px;
    height: 12px;
    background: #37ffb1;
    border-radius: 0px 4px 4px 0px;
    opacity: 0.3;
    position: absolute;
    left: 0;
    top: 25px;
  }
  .top {
    margin-bottom: 20px;
    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #37ffb1;
    }
  }
  .list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      position: relative;
      margin-bottom: 12px;
      width: 174px;
      // height: 108px;
      overflow: hidden;
      .video-js {
        width: 100%;
        height: 100%;
      }
      video {
        width: 100%;
        height: 100%;

        border-radius: 6px;
        vertical-align: bottom;
        overflow: hidden;
      }
      .mask {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
      }
      .name {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 177px;
        height: 19px;
        background: rgba($color: #000000, $alpha: 0.4);
        font-size: 12px;
        color: #e8fffb;
        text-align: center;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 10px;
      }
    }
  }
}
</style>
