
<template>
  <!-- <div :class="type == 2 ? 'dialog-red' : 'dialog-yellow'"> -->
  <el-dialog
    v-model="dialogVisible"
    width="1040px"
    top="66px"
    custom-class="el-dialog-warningDetails"
    :show-close="false"
  >
    <template v-slot:title>
      <div class="dialog-top">
        <div
          class="dialog-top-left"
          :style="'color:' + (type == 2 ? '#ff003f' : '#ff7700')"
        >
          <span class="iconfont">&#xe69f;</span>
          <span>{{ type == 2 ? "红色警报" : "黄色预警" }}</span>
        </div>
        <span class="iconfont icon-close" @click="dialogVisible = false"
          >&#xe601;</span
        >
      </div>
    </template>
    <div class="info">
      <div class="info-left">
        <div class="info-left-name">
          <span class="iconfont room">&#xe61c;</span>
          <span>{{ roomData.roomName }}</span>
        </div>
        <div class="info-left-item">
          <span class="key">警报时间</span>
          <span class="value">{{ roomData.messageTime }}</span>
        </div>
        <div class="info-left-item">
          <span class="key">设备名称</span>
          <span class="value">{{ roomData.name }}</span>
        </div>
        <div class="info-left-item" v-show="roomData.type !== 3">
          <span class="key">安全阈值</span>
          <span class="value">{{ roomData.limitValue }}</span>
        </div>
        <div class="info-left-item">
          <span class="key">警报原因</span>
          <span class="value value-red">{{ roomData.reason }}</span>
        </div>
        <div class="info-left-item">
          <span class="key">警报数值</span>
          <span class="value value-red">{{
            roomData.type == 3 ? "异常" : roomData.value1
          }}</span>
        </div>
        <div class="info-left-item">
          <span class="key">当前数值</span>
          <span
            class="value"
            :class="roomData.isRed ? 'value-red' : 'value-green'"
            v-if="roomData.type != 3"
            >{{ roomData.nowValue }}</span
          >
          <span
            class="value"
            :class="roomData.value === 1 ? 'value-red' : 'value-green'"
            v-else
            >{{ roomData.value === 1 ? "异常" : "正常" }}</span
          >
        </div>
      </div>
      <img src="../assets/images/wdbg.png" alt="" />
    </div>
    <div class="bottom-switch" v-if="type == 2 && roomData.isRed">
      <div class="title">环控处理</div>
      <div class="switch-box-out">
        <div class="switch-box">
          <div
            class="switch-box-item"
            :class="
              (!item.checked ? 'switch-box-item-close' : '') +
              ' ' +
              (item.children.length < 5 ? 'switch-box-item-column ' : '')
            "
            v-for="(item, index) in tcControlDeviceMap"
            :key="index"
          >
            <div class="top">
              <div class="left">
                <span class="iconfont" v-html="item.icon"></span>
              </div>
              <div class="right">
                <span class="name">{{ item.name }}</span>
                <el-switch
                  v-model="item.checked"
                  active-color="#00B590"
                  inactive-color="#9AA0AC"
                  @change="switchChange($event, index)"
                ></el-switch>
              </div>
            </div>
            <div
              class="children-list"
              :style="item.children.length > 4 ? 'height:100%' : ''"
            >
              <div class="children-list-in" v-if="item.children">
                <div
                  @click="childrenSelect(index, index2)"
                  :class="
                    item2.status == 1 ? 'children children-open' : 'children'
                  "
                  v-for="(item2, index2) in item.children"
                  :key="index2"
                >
                  {{ item2.name }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="switch-box-item"
            :class="!all.checked ? 'switch-box-item-close' : ''"
          >
            <div class="top">
              <div class="left">
                <span class="iconfont" v-html="all.icon"></span>
              </div>
              <div class="right">
                <span class="name">{{ all.name }}</span>
                <el-switch
                  v-model="all.checked"
                  active-color="#00B590"
                  inactive-color="#9AA0AC"
                  @change="allChange($event)"
                  :before-change="beforeChange"
                ></el-switch>
              </div>
            </div>
            <div class="tips">
              <span>紧急情况：关闭总电源</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="isNomal" v-if="!roomData.isRed && type === 2">
      <span>该警报已结束</span>
    </div>
  </el-dialog>
</template>

<script>
import { relayControl, openAll, closeAll } from '@/api/home'
export default {
  emits: ['updateDetails'],
  data () {
    return {
      dialogVisible: false,
      loading: true,
      type: 1,
      roomData: {},
      tcControlDeviceMap: {},
      all: {
        name: '电力系统',
        checked: false,
        icon: '&#xe69d;'
      }
    }
  },
  props: ['config', 'openId'],
  methods: {
    beforeChange () {
      if (this.all.checked) {
        this.$confirm(
          '关闭后实验室所有电力供应将切断，请谨慎操作！',
          '是否关闭电力系统',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
          .then(() => {
            this.allChange(!this.all.checked)
          })
          .catch(() => {})
        return false
      } else {
        return true
      }
    },
    // 电力系统
    allChange (e) {
      if (e) {
        openAll({ rId: '1', openId: this.openId }).then((res) => {
          if (Number(res.code) === 200) {
            const loading = this.$loading({
              background: 'rgba(0, 0, 0, 0.3)',
              lock: true
            })
            setTimeout(() => {
              this.$emit('updateDetails', this.roomData)
              loading.close()
            }, 1500)
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
            this.dialogVisible = false
          }
        })
      } else {
        closeAll({ rId: '1', openId: this.openId }).then((res) => {
          if (Number(res.code) === 200) {
            const loading = this.$loading({
              background: 'rgba(0, 0, 0, 0.3)',
              lock: true
            })
            setTimeout(() => {
              loading.close()
            }, 1000)
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
            this.dialogVisible = false
          }
        })
      }
      this.tcControlDeviceMap.forEach((item) => {
        if (!e) {
          item.checked = false
        }
        item.children.forEach((item2) => {
          if (!e) {
            item2.status = 0
          }
        })
      })
    },
    switchChange (e, index) {
      var arr = []
      var state = 0
      this.tcControlDeviceMap[index].children.forEach((item) => {
        if (e) {
          item.status = 1
          state = 1
        } else {
          item.status = 0
        }
        arr.push(item.deviceId)
      })
      this.relayControl({ state, deviceIds: arr })
    },
    relayControl (obj) {
      obj.openId = this.openId
      relayControl(obj).then((res) => {
        console.log(res)
        if (Number(res.code) === 200) {
          const loading = this.$loading({
            background: 'rgba(0, 0, 0, 0.3)',
            lock: true
          })
          setTimeout(() => {
            this.$emit('updateDetails', this.roomData)
            loading.close()
          }, 1000)
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
          this.dialogVisible = false
        }
      })
    },
    childrenSelect (index, index2) {
      var childrenItem = this.tcControlDeviceMap[index].children[index2]
      var status = childrenItem.status
      this.tcControlDeviceMap[index].checked = false
      if (Number(status) === 0) {
        childrenItem.status = 1
        this.relayControl({ state: 1, deviceIds: [childrenItem.deviceId] })
      } else {
        childrenItem.status = 0

        this.relayControl({ state: 0, deviceIds: [childrenItem.deviceId] })
      }
      this.tcControlDeviceMap[index].children.some((item) => {
        if (Number(item.status) === 1) {
          this.tcControlDeviceMap[index].checked = true
          return true
        }
      })
    },
    confirm () {
      this.dialogVisible = false
    },
    show (item) {
      this.dialogVisible = true
      this.type = item.warnLevel
      var tcControlDeviceMap = []
      this.tcControlDeviceMap = []
      for (const key in item.tcControlDeviceMap) {
        var obj = {}
        this.config.some((item2) => {
          if (Number(key) === item2.id) {
            obj = item2
            obj.children = item.tcControlDeviceMap[key]
            obj.checked = false
            obj.children.some((item3) => {
              if (Number(item3.status) === 1) {
                obj.checked = true
                return true
              }
            })
            tcControlDeviceMap.push(obj)
            return true
          }
        })
        if (
          Object.keys(item.tcControlDeviceMap).length ===
          tcControlDeviceMap.length
        ) {
          this.tcControlDeviceMap = tcControlDeviceMap
        }
      }
      this.roomData = item
      if (item.rstate === 1) {
        this.all.checked = true
      } else {
        this.all.checked = false
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.dialog-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dialog-top-left {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #1b1b1f;
    font-size: 18px;
    .iconfont {
      font-size: 24px;
      margin-right: 8px;
    }
  }
  .icon-close {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.3);
    padding: 8px;
    position: relative;
    top: -8px;
    right: -8px;
  }
}
.info {
  display: flex;
  align-items: center;
  .info-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #151818;
    .info-left-name {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
      .room {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1c7aff;
        font-size: 28px;
        color: #ffffff;
        margin-right: 16px;
        margin-left: 16px;
        flex-shrink: 0;
      }
    }
    .info-left-item {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      padding: 8px 0;
      .key {
        width: 50%;
        text-align: right;
        display: inline-block;
        padding-right: 24px;
      }
      .value {
        text-align: left;
        width: 50%;
        display: inline-block;
      }
      .value-red {
        color: #e6192f;
      }
      .value-green {
        color: #00ff9a;
      }
    }
  }
  img {
    flex: 1;
    height: 296px;
  }
}
.bottom-switch {
  height: 278px;
  background-image: url("../assets/images/controlBg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 0 100%;
  padding: 20px 20px 0;
  .title{
color: #fff;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .switch-box-out {
    position: relative;
    width: 100%;
    overflow: scroll;
    height: 160px;
    .switch-box {
      position: absolute;
      display: flex;
      align-items: center;
      height: 160px;
      .switch-box-item-column {
        flex-direction: column;
        .children-list {
          margin-top: 16px;
        }
      }
      .switch-box-item {
        // min-width: 229px;
        width: auto;
        height: 160px;
        background: #ffffff;
        box-shadow: 0px 4px 12px 0px #4776b8;
        border-radius: 16px;
        padding: 14px;
        background-image: url("../assets/images/labsetBg.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: 0 100%;
        margin: 0 10px;
        display: flex;
        align-items: flex-start;
        flex-shrink: 0;
        position: relative;
        .tips {
          position: absolute;
          bottom: 30px;
          right: 30px;
        }
        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-shrink: 0;
          margin-right: 40px;
          .left {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            background: #eff6ff;
            font-size: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #0474ef;
            margin-right: 10px;
          }

          .right {
            display: flex;
            align-items: center;
            flex-direction: column;

            .name {
              color: #171719;
              font-size: 18px;
              font-weight: bold;
              margin-bottom: 8px;
            }
          }
        }
        .children-list {
          width: 220px;
          position: relative;
          overflow: scroll;
          height: 74px;
          flex-shrink: 0;
          .children-list-in {
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            width: 100%;
            .children {
              // background: #ffffff;
              border-radius: 4px;
              border: 1px solid #1c7aff;
              padding: 3px 4px;
              margin: 3px 8px;
              font-size: 14px;
              width: 94px;
              text-align: center;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .children-open {
              background-color: #1c7aff;
              color: #ffffff;
            }
          }
        }

        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-top: 20px;
          color: #17171a;

          .btn {
            font-size: 16px;
            opacity: 0.5;
            height: 24px;
          }

          .btn-selsect {
            font-size: 18px;
            color: #17171a;
            opacity: 1;
          }
        }
      }
    }
    .switch-box-item-close {
      background-color: #cad3df !important;
    }
  }
}
.isNomal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  font-style: 16px;
}
.btn-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 26px;
  font-size: 16px;
  .btn-cancel {
    width: 88px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #d8e1ed;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    color: #fff;
  }
  .btn-confirm {
    width: 88px;
    height: 40px;
    background: #ffffff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1c7aff;
  }
}
</style>
