<template>
  <div class="home">
    <Container :options="{ width: 1360, height: 760 }">
      <Top :openId="openId"></Top>
      <div class="main">
        <LabInfo></LabInfo>
        <ECPanel @showDetails="showPanelDetails" @showFull='showFull' @bingXiang='bingXiang'></ECPanel>

        <AcChart :record="record"></AcChart>
         <WaringInfo
          @showDetails="showWarningDetails"
          :messageList="messageList"
          ref="WaringInfo"
        ></WaringInfo>
        <AcTable ref="AcTable" @devDetails="devDetails"></AcTable>
        <MonitoringSystem ref="MonitoringSystem"></MonitoringSystem>
      </div>
      <WarningDetails
        ref="WarningDetails"
        :config="config"
        :openId="openId"
        @updateDetails="updateDetails"
      ></WarningDetails>
      <WarningDetails2 ref="WarningDetails2"></WarningDetails2>
      <PanelDetails :openId="openId" ref="panelDetails"></PanelDetails>
      <DevDetails
        ref="devDetails"
        :openId="openId"
        :bingXiangValue='bingXiangValue'
        @devClose="devClose"
      ></DevDetails>
    </Container>
  </div>
</template>

<script>
import AcChart from '../components/ac-chart.vue'
import AcTable from '../components/ac-table.vue'
import Container from '../components/container.vue'
import ECPanel from '../components/ECPanel.vue'
import LabInfo from '../components/labInfo.vue'
import MonitoringSystem from '../components/monitoringSystem2.vue'
import Top from '../components/top.vue'
import WaringInfo from '../components/waringInfo.vue'
import WarningDetails from '../components/warningDetails.vue'
import WarningDetails2 from '../components/warningDetails2.vue'
import PanelDetails from '../components/panelDetails.vue'
import DevDetails from '../components/devDetails.vue'
import {
  pageListAndCondition,
  indexRecord,
  getControlDeviceCfg,
  getInfoByDeviceIdAndNumber,
  getOpenIdBycode
} from '@/api/home'
export default {
  name: 'Home',
  components: {
    Container,
    Top,
    ECPanel,
    LabInfo,
    WaringInfo,
    AcChart,
    MonitoringSystem,
    AcTable,
    WarningDetails,
    WarningDetails2,
    PanelDetails,
    DevDetails
  },
  data () {
    return {
      messageList: [],
      record: [],
      config: [],
      openId: '',
      bingXiangValue: {},
      changParam: false
    }
  },
  created () {
    this.pageListAndCondition()
    this.indexRecord()
    this.getControlDeviceCfg()
    setInterval(() => {
      this.pageListAndCondition()
    }, 10000)
    setInterval(() => {
      this.indexRecord()
    }, 1800000)
    var ua = navigator.userAgent.toLowerCase()
    var isWeixin = ua.indexOf('micromessenger') !== -1
    const code = this.getUrlKey('code') // 获取url参数code
    if (isWeixin) {
      if (code) {
        // 拿到code， code传递给后台接口换取opend
        getOpenIdBycode({ code }).then((res) => {
          this.openId = res.data
        })
      } else {
        this.getCodeApi('123')
      }
    } else {
      this.openId = code
    }
  },
  methods: {
    // 冰箱温度
    bingXiang (value) {
      this.bingXiangValue = value
    },
    showFull (showType) {
      try {
        this.$refs.MonitoringSystem.showFull(showType)
      } catch (error) {

      }
    },
    getUrlKey (name) {
      // 获取url 参数
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || ['', ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },

    getCodeApi (state) {
      // 获取code
      const urlNow = encodeURIComponent(window.location.href)
      const scope = 'snsapi_base' // snsapi_userinfo   //静默授权 用户无感知
      const appid = 'wxf2a20ee4793b5bf8'
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
      window.location.replace(url)
    },
    devClose () {
      this.$refs.AcTable.devHide()
    },
    updateDetails (item) {
      this.$refs.WaringInfo.show(item)
    },
    // 设备详情
    devDetails (item, changParam) {
      this.changParam = changParam
      this.$refs.devDetails.show(item, this.changParam)
    },
    getControlDeviceCfg () {
      getControlDeviceCfg().then((res) => {
        this.config = res.data
      })
    },
    showPanelDetails (item) {
      if (item.deviceId === 1766) {
        this.$refs.AcTable.showBingXiang()
        return
      }
      getInfoByDeviceIdAndNumber({
        deviceNumber: item.deviceNumber,
        deviceId: item.deviceId,
        type: item.warnType ? 2 : 1
      }).then((res) => {
        if (item.status) {
          this.$refs.panelDetails.show({
            config: this.config,
            tcControlDeviceMap: res.data.tcControlDeviceMap,
            accList: res.data.accList
          })
        } else {
          // 1：黄色，2:红色
          this.$refs.WarningDetails.show({
            deviceNumber: item.deviceNumber,
            deviceId: item.deviceId,
            roomName: res.data.laboratoryName + res.data.roomName,
            nowValue: item.num + item.unit,
            messageTime: item.warnTime,
            name: item.deviceName,
            limitValue: item.title,
            value1: item.warnValue + item.unit,
            value: item.value,
            type: item.type,
            isRed: true,
            rstate: res.data.rstate,
            warnLevel: item.warnType,
            reason: item.reason,
            tcControlDeviceMap: res.data.tcControlDeviceMap
          })
        }
      })
    },
    showWarningDetails (item) {
      switch (item.warnLevel) {
        case 1:
          this.$refs.WarningDetails.show(item)
          break
        case 2:
          console.log(item)
          this.$refs.WarningDetails.show(item)
          break
        default:
          this.$refs.WarningDetails2.show(item)
          break
      }
    },
    // 警报信息
    pageListAndCondition () {
      pageListAndCondition({ laboratoryId: 1 }).then((res) => {
        this.messageList = res.data
      })
    },
    // 门禁系统
    indexRecord () {
      indexRecord({ laboratoryId: 1 }).then((res) => {
        var record = [[], [], []]
        res.data.forEach((item) => {
          record[0].push(item.dateString)
          record[1].push(item.acRecord)
          record[2].push(item.faceRecord)
        })
        this.record = record
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog-warningDetails {
  border-radius: 34px !important;
  height: 630px;
  .el-dialog__body {
    padding: 0;
  }
}
/deep/ .el-dialog-panelDetails {
  border-radius: 34px !important;
  // height: 502px;
  background-image: url("../assets/images/controlBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 100% 100%;
  min-height: 400px;
  .el-dialog__body {
    padding: 0 0 38px;
  }
}
/deep/ .el-dialog-infoDetails {
  border-radius: 34px !important;
  height: 480px;
  .el-dialog__body {
    padding: 0;
  }
}
/deep/ .el-dialog-devDetails {
  border-radius: 34px !important;
  padding-bottom: 50px;
  .el-dialog__body {
    padding: 0;
  }
}
/deep/ .dialog-red .el-overlay {
  background-color: rgba($color: #a80000, $alpha: 0.4);
}
/deep/ .dialog-yellow {
  .el-dialog-warningDetails {
    height: 400px;
    margin-top: 200px !important;
    width: 800px !important;
    img {
      width: 250px !important;
    }
  }
}
/deep/ .dialog-yellow .el-overlay {
  background-color: rgba($color: rgb(255, 119, 0), $alpha: 0.2);
}

.main {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 24px 10px;
}
</style>
