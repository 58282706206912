
<template>
  <div class="dialog-default">
    <el-dialog
      v-model="dialogVisible"
      width="800px"
      top="170px"
      custom-class="el-dialog-infoDetails"
      :show-close="false"
    >
      <template v-slot:title>
        <div class="top">
          <div class="top-left">
            <span class="iconfont">&#xe69f;</span>
            <span>警报信息</span>
          </div>
          <span class="iconfont icon-close" @click="dialogVisible = false"
            >&#xe601;</span
          >
        </div>
      </template>
      <div class="info">
        <div class="info-left">
          <div class="info-left-name">
            <span class="iconfont room">&#xe61c;</span>
            <span class="name">{{ roomData.roomName }}</span>
          </div>
          <div class="main">
            <div class="main-left">
              <div class="info-left-item">
                <span class="key">警报时间</span>
                <span class="value">{{ roomData.messageTime }}</span>
              </div>
              <div class="info-left-item">
                <span class="key">设备名称</span>
                <span class="value">{{ roomData.name }}</span>
              </div>
              <div class="info-left-item" v-show="roomData.type!=3">
                <span class="key">安全阈值</span>
                <span class="value">{{ roomData.limitValue }}</span>
              </div>
              <div class="info-left-item">
                <span class="key">警报原因</span>
                <span class="value value-red">{{ roomData.reason }}</span>
              </div>
              <div class="info-left-item">
                <span class="key">警报数值</span>
                 <span class="value value-red">{{
              roomData.type == 3 ? "异常" : roomData.value1
            }}</span>
              </div>
              <div class="info-left-item" v-show="roomData.type!=3">
                <span class="key">警报结束数值</span>
                <span class="value">{{roomData.recoveryValue1}}</span>
              </div>
              <div class="info-left-item">
                <span class="key">结束时间</span>
                <span class="value">{{roomData.overTime}}</span>
              </div>
            </div>
            <img src="../assets/images/wdbg.png" alt="" />
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      wind: '',
      checkedLight: false,
      checkedWind: false,
      checkedOven1: false,
      checkedOven2: false,
      checkedOven3: false,
      roomData: {}
    }
  },
  methods: {
    confirm () {
      this.dialogVisible = false
    },
    selectWind (wind) {
      this.wind = wind
    },
    show (item) {
      this.roomData = item
      this.dialogVisible = true
    }
  }
}
</script>
<style lang='scss' scoped>
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .top-left {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #1b1b1f;
    font-size: 18px;
    color: #37ffb1;
    .iconfont {
      color: #37ffb1;
      font-size: 24px;
      margin-right: 8px;
    }
  }
  .icon-close {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.3);
    padding: 8px;
    position: relative;
    top:-8px;
    right: -8px;
  }
}
.info {
  display: flex;
  align-items: center;
  margin-top: 20px;
  .info-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #151818;
    .main {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .main-left {
        flex: 1;
      }
    }
    .info-left-name {
      padding: 0 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 26px;

      .name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
      .room {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1c7aff;
        font-size: 28px;
        color: #ffffff;
        margin-right: 16px;
        flex-shrink: 0;
      }
    }
    .info-left-item {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      padding: 8px 0;
      .key {
        width: 50%;
        text-align: right;
        display: inline-block;
        padding-right: 24px;
      }
      .value {
        text-align: left;
        width: 50%;
        display: inline-block;
      }
      .value-red {
        color: #e6192f;
      }
    }
  }
  img {
    height: 250px;
  }
}
</style>
