
<template>
  <div class="item-box">
    <div class="tag"></div>
    <div class="top">
      <span class="title">门禁系统</span>
      <div class="tips">
        <span class="iconfont icon-face">&#xe6c1;</span>
        <span class="txt">人脸识别</span>
        <span class="iconfont">&#xe6c1;</span>
        <span class="txt">刷卡</span>
      </div>
    </div>
    <div class="chart" ref="chart" style="width: 400px; height: 248px"></div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      option: {
        // title: { text: '环控走势表' },
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'cross', crossStyle: { color: '#E8FFFB' } }
        },
        // legend: { data: ['温度(℃)', '湿度(%/rh)'] },
        grid: { top: 20, bottom: 40, left: 40, right: 40 },
        // toolbox: { feature: { saveAsImage: {} } },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            color: '#E8FFFB'
          }
        },
        yAxis: {
          type: 'value',
          // name: '次数',
          // nameTextStyle: {
          //   color: '#ffffff'
          // },
          minInterval: 1,
          axisLabel: {

            formatter: '{value}次',
            color: '#E8FFFB'
          }
        },
        series: [
          {
            name: '人脸识别',
            type: 'line',
            data: [0, 0, 33.9, 34.4, 31.5, 31.6, 34.3],
            itemStyle: { color: '#F6BD16' },
            label: {
              color: '#E8FFFB'
            }
          },
          {
            name: '刷卡',
            type: 'line',
            data: [0, 0, 42.5, 44.7, 47.4, 50.9, 54.5],
            itemStyle: { color: '#5AD8A6' }
          }
        ]
      }
    }
  },
  props: ['record'],
  watch: {
    record (record) {
      this.option.xAxis.data = record[0]
      this.option.series[0].data = record[2]
      this.option.series[1].data = record[1]
      this.$echarts.init(this.$refs.chart).setOption(this.option)
    }
  },
  mounted () {},
  methods: {}
}
</script>
<style lang='scss' scoped>
.item-box {
  width: 404px;
  height: 312px;
  background: rgba($color: #000000, $alpha: 0.4);
  border-radius: 16px;
  border: 2px solid rgba(55, 255, 177, 0.5);

  margin-bottom: 16px;
  box-sizing: border-box;
  // opacity: 0.7;
  position: relative;
  .tag {
    width: 6px;
    height: 12px;
    background: #37ffb1;
    border-radius: 0px 4px 4px 0px;
    opacity: 0.3;
    position: absolute;
    left: 0;
    top: 25px;
  }
  .top {
    // margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #00ff9a;
    }
    .tips {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #e8fffb;
      .iconfont {
        color: #5ad8a6;
        font-size: 20px;
        margin-right: -10px;
        margin-left: 10px;
      }
      .icon-face {
        color: #f6bd16;
      }
    }
  }
}
</style>
