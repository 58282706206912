
<template>
  <div class="item-box">
    <div class="tag"></div>
    <div class="top">
      <span class="title">实验室信息</span>
    </div>
    <img class="person" :src="labData.fileUrl" alt="" />

    <div class="item">
      <span class="key">实验室</span>
      <span class="value">{{labData.name}}</span>
    </div>
    <div class="item">
      <span class="key">负责人</span>
      <span class="value">{{labData.personName}}</span>
    </div>
    <div class="item">
      <span class="key">联系方式</span>
      <span class="value">{{phone}}</span>
    </div>
    <div class="item">
      <span class="key">组织机构</span>
      <span class="value">{{labData.departmentName}}</span>
    </div>
    <div class="item">
      <span class="key">所在位置</span>
      <span class="value">{{labData.address}}</span>
    </div>
  </div>
</template>

<script>
import { laboratory } from '@/api/home'
export default {
  components: {},
  data () {
    return {
      labData: {},
      phone: ''
    }
  },

  created () {
    this.laboratory()
  },

  methods: {
    laboratory () {
      laboratory(1).then((res) => {
        this.labData = res.data[0]
        this.Mobile2()
      })
    },
    Mobile2 () {
      var value = this.labData.phone.slice()
      value = value.replace(/\s*/g, '')
      var result = []
      for (var i = 0; i < value.length; i++) {
        if (i === 3 || i === 7) {
          result.push(' ' + value.charAt(i))
        } else {
          result.push(value.charAt(i))
        }
      }
      this.phone = result.join('')
    }
  }
}
</script>
<style lang='scss' scoped>
.item-box {
  width: 404px;
  height: 312px;
  background: rgba($color: #000000, $alpha: 0.4);
  border-radius: 16px;
  border: 2px solid rgba(55, 255, 177, 0.5);
  padding: 20px;
  box-sizing: border-box;
  // opacity: 0.7;
  position: relative;
  margin-bottom: 16px;
  .person {
    width: 90px;
    height: auto;
    border-radius: 4px;
    border: 1px solid #ffffff;
    position: absolute;
    right: 24px;
    top: 75px;
  }
  .tag {
    width: 6px;
    height: 12px;
    background: #37ffb1;
    border-radius: 0px 4px 4px 0px;
    opacity: 0.3;
    position: absolute;
    left: 0;
    top: 25px;
  }
  .top {
    margin-bottom: 32px;
    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #37ffb1;
    }
  }
  .item {
    font-size: 16px;
    font-weight: 400;
    color: #e8fffb;
    margin-top: 16px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    .key {
      font-weight: 500;
      width: 70px;
      margin-right: 20px;
      color: rgba(232, 255, 251, 0.8);
      text-align-last: justify;
    }
    .value {
      flex: 1;
      text-align: justify;
      text-justify: newspaper;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      line-height: 24px;
    }
  }
}
</style>
