import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '../src/assets/iconfont/iconfont.css'

import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import 'video.js/dist/video-js.css'

import * as echarts from 'echarts'
window.NODE_ENV = process.env.NODE_ENV
const app = createApp(App)
app.use(ElementPlus)
app.use(router)
app.config.globalProperties.$echarts = echarts
app.mount('#app')
