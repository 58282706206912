
<template>
  <div class="item-box" style="padding-top: 12px">
    <div class="top">
      <div class="type-list">
        <div class="types">
          <div
            :class="typeSelect === index ? 'type typeSelect' : 'type'"
            v-for="(item, index) in ['仪器物联', '危化品']"
            :key="index"
            @click="selectType(index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="status-list" v-show="typeSelect === 0">
        <span class="status-g"></span>
        <span class="status-t">运行</span>
        <span class="status-b"></span>
        <span class="status-t">待机</span>
        <span class="status-r"></span>
        <span class="status-t">关机</span>
        <span class="status-gray"></span>
        <span class="status-t">离线</span>
      </div>
    </div>
    <div class="devList" v-show="typeSelect === 0">
      <div class="hr">
        <span class="w1">仪器设备</span>
        <span class="w2">仪器状态</span>
        <span class="w2">预约次数</span>
        <span class="w2">使用时长</span>
      </div>
      <div class="item" v-if="other" @click="show(other)" style="padding-bottom: 0">
        <div class="item-dev w1">
          <img :src="other.img" alt="" />
          <span class="w1" id="instruName">{{ other.instruName }}</span>
        </div>

        <div class="status w2">
          <span :class="'status-' + estatus(other.eStatus).c"></span>
          <span class="status-t">{{ estatus(other.eStatus).t }}</span>
        </div>
        <span class="w2"
          >{{ other.orderCount >= 10000 ? "9999+" : other.orderCount }}次</span
        >
        <span class="w2">{{ runTime(other.runTime) }}</span>
      </div>
      <swiper
        v-if="list && list.length > 0"
        :watchSlidesProgress="true"
        :watchSlidesVisibility="true"
        :slidesPerView="2"
        class="mySwiper"
        :touchAngle="180"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        :observer="true"
        :direction="'vertical'"
        :loop="list.length > 2"
        :loopFillGroupWithBlank="false"
        :height="140"
      >
        <swiper-slide
          v-for="item in list"
          :key="item.instruId"
          height="60"
          @click="show(item)"
        >
          <div class="item">
            <div class="item-dev w1">
              <img :src="item.img" alt="" />
              <span class="w1" id="instruName">{{ item.instruName }}</span>
            </div>

            <div class="status w2">
              <span :class="'status-' + estatus(item.eStatus).c"></span>
              <span class="status-t">{{ estatus(item.eStatus).t }}</span>
            </div>
            <span class="w2"
              >{{
                item.orderCount >= 10000 ? "9999+" : item.orderCount
              }}次</span
            >
            <span class="w2">{{ runTime(item.runTime) }}</span>
          </div>
        </swiper-slide>
      </swiper>
      <div class="dev-none" v-show="list.length === 0">
        <img style="margin: 0 auto" src="../assets/images/devnone.png" alt="" />
        <span>暂无仪器设备</span>
      </div>
    </div>
    <div class="danger" v-show="typeSelect === 1">
      <div class="danger-left">
        <div class="title">危化品智能管控柜</div>
        <div
          class="danger-num-item"
          :class="
            index === 1
              ? 'danger-num-item-week'
              : index === 2
              ? 'danger-num-item-mother'
              : ''
          "
          v-for="(item, index) in 2"
          :key="index"
        >
          <span class="other-ltfttop"></span>
          <span class="other-righttop"></span>
          <div>{{ index === 0 ? alln : allw }}</div>
          <div class="date">
            <span>{{ index === 0 ? "总数量(瓶)" : "总重量(g)" }}</span>
          </div>
        </div>
      </div>
      <div class="danger-right">
        <!-- <span class="title">危化品出入库走势图</span> -->
        <div
          class="chart"
          ref="chart"
          style="width: 340px; height: 220px"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/swiper.scss'
// import Swiper core and required modules
import SwiperCore, { Autoplay } from 'swiper/core'
import { instrumentUnit, getHazardousChemicals } from '@/api/home'

// install Swiper modules
SwiperCore.use([Autoplay])
export default {
  emits: ['devDetails'],
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      list: [],
      instruId: '',
      other: null,
      typeSelect: 0,
      timer: null,
      activeIndex: 1,
      rId: null,
      alln: 0,
      allw: 0,
      option: {
        title: {
          left: '40',
          text: '危化品出入库走势图',
          textStyle: {
            fontSize: 12,
            color: '#37ffb1',
            fontWeight: '400'
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['入库', '出库'],
          right: '0',
          icon: 'roundRect',
          textStyle: {
            color: '#fff',
            fontSize: 10
          }
        },
        grid: {
          // left: '3%',
          // right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            fontSize: 12,
            color: '#ffffff',
            interval: 0
          },
          axisTick: {
            show: false
          },
          data: []
        },
        yAxis: {
          name: '瓶数',
          nameTextStyle: {
            padding: [0, 40, 0, 0],
            color: '#fff',
            fontSize: 12
          },
          axisLabel: {
            fontSize: 11,
            color: '#ffffff'
          },
          minInterval: 1,
          splitLine: {
            lineStyle: {
              type: 'solid',
              color: 'rgba(255,255,255,0.3)',
              width: '2'
            }
          },
          type: 'value'
        },
        series: [
          {
            name: '入库',
            type: 'line',
            data: []
          },
          {
            name: '出库',
            type: 'line',
            data: []
          }
        ]
      }
    }
  },
  created () {
    this.instrumentUnit()
    setInterval(() => {
      this.instrumentUnit()
    }, 5000)
  },
  mounted () {
    this.echart = this.$echarts.init(this.$refs.chart)
  },

  methods: {
    showBingXiang () {
      this.list.some((item, index) => {
        if (item.instruId === 1725) {
          this.show(item, true)
        }
      })
    },
    getHazardousChemicals () {
      getHazardousChemicals({ parameter: {}, pageSize: 7, page: 1 }).then(res => {
        if (Number(res.code) === 200) {
          this.alln = res.data.amount
          this.allw = res.data.totalWeight
          this.option.xAxis.data = Object.keys(res.data.trendChartEnter).map(item => {
            var index = item.indexOf('-')
            return item.substr(index + 1)
          }).reverse()
          this.option.series[0].data = Object.values(res.data.trendChartEnter).reverse()
          this.option.series[1].data = Object.values(res.data.trendChartOut).reverse()
          this.echart.setOption(this.option)
        }
      })
    },
    selectType (index) {
      this.typeSelect = index

      SwiperCore.use([Autoplay])
      if (index === 1) {
        this.getHazardousChemicals()
      }
    },
    runTime (runTime) {
      let secondTime = parseInt(runTime) // 将传入的秒的值转化为Number
      let min = 0 // 初始化分
      let h = 0 // 初始化小时
      var result = ''
      if (secondTime > 60) {
        // 如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60) // 获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60) // 获取秒数，秒数取佘，得到整数秒数
        result = min + '分钟'
        if (min > 60) {
          // 如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60) // 获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60) // 获取小时后取佘的分，获取分钟除以60取佘的分
          result = h + '小时' + (min > 0 ? min + '分钟' : '')
          if (h >= 10000) {
            result = '9999+小时'
          }
        }
      } else {
        if (secondTime > 0) {
          result = '1分钟'
        } else {
          result = '0分钟'
        }
      }
      return result
    },
    devHide () {
      this.instruId = ''
    },
    show (item, changParam = false) {
      this.instruId = item.instruId
      if (item.instruId === 1725) {
        changParam = true
      }
      this.$emit('devDetails', item, changParam)
    },
    estatus (estatus) {
      // 设备状态(1:运行,2:待机,3:关机,4:离线)
      switch (estatus) {
        case 1:
          return { t: '运行', c: 'g' }

        case 2:
          return { t: '待机', c: 'b' }

        case 3:
          return { t: '关机', c: 'r' }

        case 4:
          return { t: '离线', c: 'gray' }

        default:
          return { t: '离线', c: 'gray' }
      }
    },
    instrumentUnit () {
      instrumentUnit({ parameter: { id: '1' }, pageSize: 10, page: 1 }).then(
        (res) => {
          if (Number(res.code) === 200 && res.data.equipList && res.data.equipList.rows) {
            this.list = JSON.parse(JSON.stringify(res.data.equipList.rows))
            this.list.some((item, index) => {
              if (item.instruId === 1718) {
                this.other = item
                this.list.splice(index, 1)
                return true
              }
            })
            if (this.instruId) {
              res.data.equipList.rows.some((item) => {
                if (item.instruId === this.instruId) {
                  if (item.instruId === 1725) {
                    this.$emit('devDetails', item, true)
                  } else {
                    this.$emit('devDetails', item)
                  }
                }
              })
            }
          }
        }
      )
    }
  }
}
</script>
<style lang='scss' scoped>
.item-box {
  width: 475px;
  height: 312px;
  background: rgba($color: #000000, $alpha: 0.4);
  border-radius: 16px;
  border: 2px solid rgba(55, 255, 177, 0.5);
  padding-top: 20px;
  box-sizing: border-box;
  // opacity: 0.7;
  position: relative;
  overflow: hidden;
  .tag {
    width: 6px;
    height: 12px;
    background: #00ff9a;
    border-radius: 0px 4px 4px 0px;
    opacity: 0.3;
    position: absolute;
    left: 0;
    top: 25px;
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .type-list {
      display: flex;
      justify-content: center;
      .types {
        display: flex;
        align-items: center;
        justify-content: center;
        .type {
          color: #37ffb1;
          font-size: 13px;
          font-weight: bold;
          // border: 25px double #0b60b3;
          // border-image: url(../assets/images/room.png) 36;
          background-image: url(../assets/images/room.png);
          background-size: 100% 100%;
          height: 40px;
          width: 104px;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.9;
          margin-right: 5px;
          padding-bottom: 1px;
        }
        .typeSelect {
          background-image: url(../assets/images/roomSelect.png);
          opacity: 1;
          color: #031e43;
        }
      }
    }
    .status-list {
      display: flex;
      align-items: center;
      .status-t {
        font-size: 12px;
        margin-left: 4px;
        color: #e8fffb;
      }
      .status-b,
      .status-g,
      .status-r,
      .status-gray {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #44aaff;
        border: 1px solid #ffffff;
        margin-left: 16px;
      }
    }
  }
  .hr {
    display: flex;
    align-items: center;
    background: rgba($color: #fafafa, $alpha: 0.1);
    height: 40px;
    text-align: center;
    font-size: 14px;
    color: #e8fffb;
    font-weight: 500;
    margin: 4px 0 0px;
  }
  .dev-none {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    font-size: 14px;
    color: #e8fffb;
  }

  .item {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    text-align: center;
    color: #e8fffb;
    font-size: 14px;
    .item-dev {
      display: flex;
      align-items: center;
      #instruName {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        width: 100px;
      }
      img {
        width: 56px;
        height: 56px;
        background: #d8d8d8;
        border: 1px solid #979797;
        flex-shrink: 0;
        margin-left: 20px;
        margin-right: 14px;
      }
      span {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        // white-space: nowrap;
      }
    }
    .status {
      display: flex;
      align-items: center;
      justify-content: center;
      .status-b,
      .status-g,
      .status-r,
      .status-gray {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #44aaff;
        border: 1px solid #ffffff;
        margin-right: 4px;
      }
    }
  }

  .w1 {
    width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;
    padding: 0 4px;
  }
  .w2 {
    width: 20%;
    padding: 0 4px;
  }

  .status-g {
    background: #3dea1f !important;
  }
  .status-r {
    background: #ea1f6f !important;
  }
  .status-gray {
    background: #84889e !important;
  }
}
.danger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .danger-left {
    margin-left: 20px;
    margin-top: 20px;
    .title {
    font-size: 11px;
    color: #37ffb1;
    padding-bottom: 30px;
    padding-top: 2px;
  }
    .danger-num-item {
      min-width: 108px;
      height: 56px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #37ffb1;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 40px;
      clip-path: polygon(
        0 0,
        calc(100% - 0px) 0,
        100% 0px,
        100% calc(100% - 0px),
        calc(100% - 0px) 100%,
        16px 100%,
        0 calc(100% - 16px),
        0 0px
      );
      background: linear-gradient(
            -45deg,
            rgba(55, 255, 177, 1) 6px,
            rgba(33, 155, 179, 0.1) 0
          )
          bottom right,
        linear-gradient(
            45deg,
            rgba(55, 255, 177, 1) 10px,
            rgba(33, 155, 179, 0.1) 0
          )
          bottom left;
      background-repeat: no-repeat;
      border: solid 2px rgba(55, 255, 177, 1);
      position: relative;
      .other-ltfttop {
        position: absolute;
        top: 0;
        left: -1px;
        width: 0px;
        height: 14px;
        border-width: 0px 0px 3px 3px;
        border-style: none none solid solid;
        border-color: transparent transparent transparent rgba(55, 255, 177, 1);
      }
      .other-righttop {
        position: absolute;
        top: -1px;
        right: -1px;
        // width: 107px;
        // height: 34px;
        width: 42px;
        height: 14px;
        background-color: rgba(55, 255, 177, 1);
        -webkit-clip-path: polygon(
          0px 0,
          100% 0,
          100% 14px,
          40px 6px,
          36px 3px,
          0px 3px
        );
        clip-path: polygon(
          0px 0,
          100% 0,
          100% 14px,
          40px 6px,
          36px 3px,
          0px 3px
        );
      }
      .date {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        font-weight: normal;
      }
    }
    .danger-num-item-week {
      border-color: rgba(226, 255, 55, 1);
      color: rgba(226, 255, 55, 1);
      background: linear-gradient(
            -45deg,
            rgba(226, 255, 55, 1) 6px,
            rgba(33, 155, 179, 0.1) 0
          )
          bottom right,
        linear-gradient(
            45deg,
            rgba(226, 255, 55, 1) 10px,
            rgba(33, 155, 179, 0.1) 0
          )
          bottom left;
      .other-ltfttop {
        border-color: transparent transparent transparent rgba(226, 255, 55, 1);
      }
      .other-righttop {
        background-color: rgba(226, 255, 55, 1);
      }
    }
  }
  .danger-right{

  }
}
</style>
