
<template>
  <div class="dialog-default">
    <el-dialog
      v-model="dialogVisible"
      top="60px"
      width="600px"
      custom-class="el-dialog-panelDetails"
      :show-close="false"
    >
      <template v-slot:title>
        <div class="top">
          <div class="top-left">
            <span>环控管理</span>
          </div>
          <span class="iconfont icon-close" @click="dialogVisible = false">&#xe601;</span>
        </div>
      </template>

      <div class="bottom-switch">
        <panelDetailsItem :openId='openId' v-for="(itemData,index3) in accList" :itemData='itemData' :key='index3'></panelDetailsItem>
        <div class="switch-box">
          <div
            class="switch-box-item"
            :class="
              (!item.checked ? 'switch-box-item-close' : '') +
              ' ' +
              (item.children.length < 5 ? 'switch-box-item-column ' : '')
            "
            v-for="(item, index) in tcControlDeviceMap"
            :key="index"
          >
            <div class="top">
              <div class="left">
                <span class="iconfont" v-html="item.icon"></span>
              </div>
              <div class="right">
                <span class="name">{{ item.name }}</span>
                <el-switch
                  v-model="item.checked"
                  @change="switchChange($event, index)"
                  style="border-radius: 16px;overflow: hidden;"
                ></el-switch>
              </div>
            </div>
            <div
              class="children-list"
              :style="item.children.length > 4 ? 'width:280px' : ''"
            >
              <div class="children-list-in">
                <div
                  @click="childrenSelect(index, index2)"
                  :class="
                    item2.status == 1
                      ? 'children children-open'
                      : 'children'
                  "
                  v-for="(item2, index2) in item.children"
                  :key="index2"
                >
                  {{ item2.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { relayControl } from '@/api/home'
import panelDetailsItem from './panelDetailsItem.vue'
export default {
  components: {
    panelDetailsItem
  },
  data () {
    return {
      dialogVisible: false,
      tcControlDeviceMap: [],
      accList: []
    }
  },
  props: ['openId'],
  watch: {
    checkedOven4 (state) {
      if (!state) {
        this.lightList = []
      }
    }
  },
  methods: {
    switchChange (e, index) {
      var arr = []
      var state = 0
      this.tcControlDeviceMap[index].children.forEach((item) => {
        if (e) {
          item.status = 1
          state = 1
        } else {
          item.status = 0
        }
        arr.push(item.deviceId)
      })
      this.relayControl({ state, deviceIds: arr })
    },
    relayControl (obj) {
      obj.openId = this.openId
      relayControl(obj).then((res) => {
        if (Number(res.code) !== 200) {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
          this.dialogVisible = false
        }
      })
    },
    childrenSelect (index, index2) {
      var childrenItem = this.tcControlDeviceMap[index].children[index2]
      var status = childrenItem.status
      this.tcControlDeviceMap[index].checked = false
      if (Number(status) === 0) {
        childrenItem.status = 1
        this.relayControl({ state: 1, deviceIds: [childrenItem.deviceId] })
      } else {
        childrenItem.status = 0

        this.relayControl({ state: 0, deviceIds: [childrenItem.deviceId] })
      }
      this.tcControlDeviceMap[index].children.some((item) => {
        if (Number(item.status) === 1) {
          this.tcControlDeviceMap[index].checked = true
          return true
        }
      })
    },
    confirm () {
      this.dialogVisible = false
    },
    selectWind (wind) {
      this.wind = wind
    },
    show (item) {
      this.accList = item.accList
      this.tcControlDeviceMap = []
      for (const key in item.tcControlDeviceMap) {
        var obj = {}
        item.config.some((item2) => {
          if (Number(key) === item2.id) {
            obj = item2
            obj.children = item.tcControlDeviceMap[key]
            obj.checked = false
            obj.children.some((item3) => {
              if (Number(item3.status) === 1) {
                obj.checked = true
                return true
              }
            })
            this.tcControlDeviceMap.push(obj)
            return true
          }
        })
      }
      this.tcControlDeviceMap.sort(function (a, b) {
        if (a.children.length < b.children.length) {
          return 1
        } else {
          return -1
        }
      })
      this.dialogVisible = true
    }
  }
}
</script>
<style lang='scss' scoped>

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .top-left {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #fff;
    font-size: 18px;
  }
  .icon-close {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.8);
  }
}
.bottom-switch {
  padding: 0 30px;
  .switch-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .switch-box-item-column {
      flex-direction: column;
      .children-list {
        margin-top: 16px;
      }
    }
    .switch-box-item {
      height: 168px;
      background: #ffffff;
      // box-shadow: 0px 4px 12px 0px #4776b8;
      border-radius: 16px;
      padding: 14px;
      background-image: url("../assets/images/labsetBg.png");
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: 0 100%;
      margin: 10px 10px 0;
      display: flex;
      align-items: flex-start;
      flex-shrink: 0;
      overflow: hidden;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        margin-right: 40px;
        .left {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          background: #eff6ff;
          font-size: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #0474ef;
          margin-right: 44px;
          flex-shrink: 0;
        }

        .right {
          display: flex;
          align-items: center;
          flex-direction: column;

          .name {
            color: #171719;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 8px;
          }
        }
      }
      .children-list {
        width: 220px;
        position: relative;
        height: 100%;
        overflow: scroll;
        flex-shrink: 0;
        .children-list-in {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          position: absolute;
          width: 100%;
          .children {
            // background: #ffffff;
            border-radius: 3px;
            border: 1px solid #1c7aff;
            padding: 3px 4px;
            width: 94px;
            text-align: center;
            display: inline-block;
            margin: 3px 8px;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .children-open {
            background-color: #1c7aff;
            color: #ffffff;
          }
        }
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 20px;
        color: #17171a;

        .btn {
          font-size: 16px;
          opacity: 0.5;
          height: 24px;
        }

        .btn-selsect {
          font-size: 18px;
          color: #17171a;
          opacity: 1;
        }
      }
    }

    .switch-box-item-close {
      background-color: #cad3df !important;
    }
  }
}
.btn-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  font-size: 16px;
  .btn-cancel {
    width: 88px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #d8e1ed;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    color: #fff;
  }
  .btn-confirm {
    width: 88px;
    height: 40px;
    background: #ffffff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1c7aff;
  }
}
</style>
