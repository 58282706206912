import request from '@/utils/request'
export function tcControl (data) {
  // 实验室数据中心_环控面板
  return request({
    url: '/ysa-tc-service/labStatisticsController/tcControl',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function pageListAndCondition (data) {
  // 环控管理_环控消息
  return request({
    url: '/ysa-tc-service/labStatisticsController/pageListAndCondition',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function indexRecord (data) {
  // 实验室数据中心_门禁系统
  return request({
    url: '/ysa-ac-service/statisticsController/indexRecord',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function instrumentUnit (data) {
  // 仪器设备(大屏
  return request({
    url: '/ysa-laboratory-service/laboratory/instrumentUnit',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function getTime (data) {
  // 实验室数据中心_获取时间
  return request({
    url: '/ysa-ac-service/statisticsController/getTime',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function getVideoInfo (data) {
  // 获取摄像头信息
  return request({
    url: '/ysa-video-service/video/getVideoInfo',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function getInfoByDeviceIdAndNumber (data) {
  // 环控设备_设备详情
  return request({
    url: '/ysa-tc-service/labStatisticsController/getInfoByDeviceIdAndNumber',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function getControlDeviceCfg (data) {
  // 实验室数据中心_获取环控系统配置
  return request({
    url: '/ysa-tc-service/labStatisticsController/getControlDeviceCfg',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function relayControl (data) {
  // 继电器控制
  return request({
    url: '/ysa-tc-service/iot/relay/relayControlPanel',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function laboratory (data) {
  // 实验室详情
  return request({
    url: '/ysa-laboratory-service/laboratory/getLabInfoById',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify({ laboratoryId: data })
  })
}

export function queryLaboratoryRoomList (data) {
  // 分页房间查询
  return request({
    url: '/ysa-laboratory-service/laboratory/pageRoomList',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function closeAll (data) {
  // 关闭所有继电器
  return request({
    url: '/ysa-tc-service/iot/relay/closeAllPanel',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function openAll (data) {
  // 打开所有继电器
  return request({
    url: '/ysa-tc-service/iot/relay/openAllPanel',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function switchMachine (data) {
  // 仪器猫/云盒子开关机
  return request({
    url: '/ysa-equip-service/callDataCenter/switchMachinePanel',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function switchMachineStatus (data) {
  // 仪器猫/云盒子开关机状态
  return request({
    url: '/ysa-equip-service/callDataCenter/switchMachineStatus',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function getOpenIdBycode (data) {
  // code换openid
  return request({
    url: '/ysa-base/wechatinfo/getOpenIdBycode',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function tcControlType (data) {
  // 实验室数据中心_环控面板_传感器区分
  return request({
    url: '/ysa-tc-service/labStatisticsController/tcControlType',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function getHazardousChemicals (data) {
  // 实验室数据中心(大)_危化品列表
  return request({
    url: '/ysa-tc-service/labStatisticsController/getHazardousChemicals',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function getDeviceStatus (data) {
  // 获取开关状态
  return request({
    url: '/ysa-tc-service/iot/relay/getDeviceStatus',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function relayControlBypwd (data) {
  // 关电
  return request({
    url: '/ysa-tc-service/iot/relay/relayControlBypwd',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function accControll (data) {
  // 空调控制
  return request({
    url: '/ysa-tc-service/iot/accDevice/accControll',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
export function accSetting (data) {
  // 空调时间设置
  return request({
    url: '/ysa-tc-service/iot/accDevice/accSetting',
    method: 'post',
    datatype: 'json',
    headers: {
      'Content-Type': 'application/json;'
    },
    data: JSON.stringify(data)
  })
}
