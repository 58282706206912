
<template>
  <div class="item-box">
    <div class="top">
      <div class="type-list">
        <div class="types">
          <div
            :class="typeSelect === index ? 'type typeSelect' : 'type'"
            v-for="(item, index) in ['环控','应急处置']"
            :key="index"
            @click="selectType(index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="pages">{{activeIndex}}/{{panelData.length}}</div>
    </div>
    <swiper
      :watchSlidesProgress="true"
      :watchSlidesVisibility="true"
      class="mySwiper"
      :height="252"
      :touchAngle='180'
      ref="mySwiper"
      :initialSlide='0'
      @slideChange="swiperChange"
    >
      <swiper-slide v-for="(item2, index2) in panelData" :key="index2">
        <div class="item-list">
          <div
            :class="
              item && (!item.status || (item.type === 3 && item.value === 1))
                ? 'item item-red'
                : 'item'
            "
            v-for="(item, index) in item2"
            :key="index"
            @click="showDetails(item)"
          >
            <span class="title" v-if="item">{{ item.deviceName }}</span>
            <div class="num-box" v-if="item">
              <span class="iconfont" v-html="item.icon"></span>
              <div class="num-box-right">
                <span class="num Helvetica-Neue" v-if="item.type === 3||item.type === 14">{{
                  item.name
                }}</span>
                <div class="num-content" v-else>
                  <CountTo
                    :start-val="item.oldNum"
                    :decimals="item.decimals"
                    :end-val="item.num"
                    :duration="1000"
                  ></CountTo>
                  {{ item.unit }}
                </div>
                <span class="txt">{{ item.testParam }}</span>
              </div>
            </div>
            <span class="tips" v-if="item && (item.type != 3&&item.type != 14)">{{
              item.title
            }}</span>
            <span class="none" v-show="!item">暂无数据</span>
            <bolang></bolang>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { queryLaboratoryRoomList, tcControlType } from '@/api/home'
import bolang from './bolang.vue'
import CountTo from './vue-countTo/vue-countTo.vue'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/swiper.scss'
// import Swiper core and required modules
import SwiperCore, { Autoplay } from 'swiper/core'

// install Swiper modules
SwiperCore.use([Autoplay])
export default {
  emits: ['showDetails', 'showFull', 'bingXiang'],
  components: {
    CountTo,
    bolang,
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      panelData: [],
      roomList: [],
      oldNum: [],
      typeSelect: 0,
      timer: null,
      activeIndex: 1,
      rId: null
    }
  },
  created () {
    this.queryLaboratoryRoomList()
  },
  watch: {
    typeSelect (typeSelect) {
      // if (typeSelect === 0) {
      //   this.timer = setInterval(() => {
      //     this.tcControl2()
      //   }, 2000)
      // } else {
      //   clearInterval(this.timer)
      // }
    }
  },

  methods: {
    swiperChange (index) {
      this.activeIndex = (index.activeIndex + 1)
    },
    selectType (index) {
      this.typeSelect = index
      this.tcControl()
    },
    queryLaboratoryRoomList () {
      queryLaboratoryRoomList({ parameter: { laboratoryId: '1' } }).then(
        (res) => {
          var roomList = res.data.rows
          if (roomList.length > 0) {
            this.rId = roomList[0].roomId
            this.tcControl()
            setInterval(() => {
              this.tcControl()
              this.tcControl2()
            }, 2000)
            // this.timer = setInterval(() => {
            //   this.tcControl2()
            // }, 2000)
          }
        }
      )
    },
    tcControl2 () {
      tcControlType({ rId: this.rId, sensorType: 1 }).then((res) => {
        if (Array.isArray(res.data) && res.data.length > 0) {
          var showVideo = 0
          res.data.forEach((item, index) => {
            // 视频报警
            if (item.presetState === 1 && item.warnType === 2) {
              showVideo = 1
            }
            if (index === res.data.length - 1) {
              if (showVideo === 1) {
                this.$emit('showFull', true)
              } else {
                this.$emit('showFull', false)
              }
            }
          })
        }
      })
    },
    tcControl () {
      tcControlType({ rId: this.rId, sensorType: this.typeSelect }).then((res) => {
        var panelData = res.data
        var oldNum = JSON.parse(JSON.stringify(this.oldNum))
        this.oldNum = []

        if (Array.isArray(panelData) && panelData.length > 0) {
          // var showVideo = 0
          panelData.forEach((item, index) => {
            // 1:光照强度_2:噪音强度_3:水(管道压力)_4:温度传感器_5:湿度传感器_6:氧气浓度_7:压力传感器8:可燃气
            // 1:光_2:噪_3:水浸_4:温_5:湿_6:氧_7:压,8:可燃气
            var unit = ''
            switch (item.type) {
              case 1:
                item.name = '光照'
                item.icon = '&#xe619;'
                unit = 'Lux'
                break
              case 2:
                item.name = '噪音'
                item.icon = '&#xe6be;'
                unit = 'dB'
                break
              case 3:
                item.name = item.value === 0 ? '正常' : '异常'
                item.icon = '&#xe6bd;'
                unit = ''
                break
              case 4:
                item.name = '温度'
                item.icon = '&#xe66d;'
                unit = '℃'
                break
              case 5:
                item.name = '湿度'
                item.icon = '&#xe66c;'
                unit = '%RH'
                break
              case 6:
                item.name = '氧气浓度'
                item.icon = '&#xe6bf;'
                unit = '%'
                break
              case 7:
                item.name = '压力'
                item.icon = '&#xe6c2;'
                unit = 'Mpa'
                break
              case 8:
                item.name = '可燃气体含量'
                item.icon = '&#xe6ba;'
                unit = '%LEL'
                break
              case 9:
                item.name = '烟雾'
                item.icon = '&#xe624;'
                unit = 'ppm'
                break
              case 10:
                item.icon = '&#xe6cb;'
                break
              case 11:
                item.icon = '&#xe60a;'
                break
              case 12: // 高低温
                item.icon = '&#xe66d;'
                break
              case 13: // 震动
                item.icon = '&#xeb07;'
                break
              case 14: // 门磁
                item.icon = '&#xe633;'
                item.name = item.value === 0 ? '开' : '关'
                break
              default:
                item.icon = '&#xe66d;'
                break
            }
            item.status = true

            if (item.type !== 3) {
              if (item.min != null && item.value <= item.min) {
                item.status = false
                item.reason = item.testParam + '过低'
              }
              if (item.max != null && item.value >= item.max) {
                item.status = false
                item.reason = item.testParam + '过高'
              }
            }
            if (item.type === 3 && item.value === 1) {
              item.status = false
              item.reason = item.testParam
            }
            this.oldNum.push(item.value)
            item.num = item.value
            item.oldNum = oldNum[index] || 0
            unit = item.unit
            var decimals = String(item.value).split('.')
            item.decimals = decimals.length > 1 ? decimals[1].length : 1

            if (item.min != null && item.max == null) {
              item.title = `x${
                item.min != null ? ' ＞ ' + item.min : ''
              }${unit}`
            } else {
              item.title = `${item.min != null ? item.min + ' ＜ ' : ''}x${
                item.max != null ? ' ＜ ' + item.max : ''
              }${unit}`
            }
            if (item.deviceId === 1766) {
              this.$emit('bingXiang', { value: item.value, status: item.status })
            }
          })
          this.panelData = this.group(panelData, 6)
        } else {
          this.panelData = [[null, null, null, null, null, null]]
        }
      })
    },
    group (array, subGroupLength) {
      var index = 0
      var newArray = []

      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)))
      }

      return newArray
    },
    showDetails (data) {
      this.$emit('showDetails', data)
    }
  }
}
</script>
<style lang='scss' scoped>
.item-box {
  width: 475px;
  height: 312px;
  background: rgba($color: #000000, $alpha: 0.4);
  border-radius: 16px;
  border: 2px solid rgba(55, 255, 177, 0.5);
  padding: 12px 20px 12px;
  box-sizing: border-box;
  // opacity: 0.7;
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
  .top {
    display: flex;
    align-items: center;
    position: relative;
    .pages{
      font-size: 13px;
      color: #E8FFFB;
      position: absolute;
      right: 15px;
      bottom: 0;
    }
    .type-list {
    display: flex;
    justify-content: center;
    .types {
      display: flex;
      align-items: center;
      justify-content: center;
      .type {
        color: #37ffb1;
        font-size: 13px;
        font-weight: bold;
        // border: 25px double #0b60b3;
        // border-image: url(../assets/images/room.png) 36;
        background-image: url(../assets/images/room.png);
        background-size: 100% 100%;
        height: 40px;
        width: 104px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.9;
        margin-right: 15px;
        padding-bottom: 1px;
      }
      .typeSelect {
        background-image: url(../assets/images/roomSelect.png);
        opacity: 1;
        color: #031e43;
      }
    }
  }
  }
  .item-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
    width: 100%;
    height: 240px;
    .item {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 131px;
      height: 110px;
      border-radius: 12px;
      border: 1px solid #37ffb1;
      padding: 8px 0;
      margin: 5px 6px;
      // background-image: url("../assets/images/labsetBg.png");
      // background-repeat: no-repeat;
      // background-size: 100% auto;
      // background-position: 0 100%;
      position: relative;
      top: 0;
      left: 0;
      overflow: hidden;
      .title {
        color: #00ffa2;
        font-size: 14px;
        width: 100%;
        text-align: left;
        line-height: 14px;
        padding-left: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .num-box {
        display: flex;
        align-items: center;
        color: #00ff9a;
        margin: 12px 0 8px;
        .iconfont {
          font-size: 32px;
          margin-right: 4px;
          flex-shrink: 0;
        }
        .num-box-right {
          display: flex;
          flex-direction: column;
          font-size: 12px;
          .num-content {
            font-size: 16px;
          }
          .num {
            font-size: 18px;
            font-weight: 600;
          }
          .txt {
            opacity: 1;
          }
        }
      }
      .none {
        color: #e8fffb;
        font-size: 14px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .tips {
        color: #bcd8df;
        font-size: 12px;
        opacity: 1;
      }
    }
    .item-red {
      border-color: #e6192f;
      background-color: rgba($color: #e6192f, $alpha: 0.3);
      .title {
        color: #e6192f;
      }
      .num-box {
        color: #e6192f;
      }
      .tips {
        color: #d7c5cf;
      }
    }
  }
}
</style>
