
<template>
  <div class="switch-box-item">
    <div class="item-left">
      <div class="row">
        <div class="left">
          <span class="iconfont">&#xe90d;</span>
        </div>
        <div class="right">
          <span class="name">{{ name }}</span>
          <el-switch
            v-model="checked"
            @change="switchChange($event, index)"
            style="border-radius: 16px; overflow: hidden"
          ></el-switch>
        </div>
      </div>
      <div class="row">
        <span style="font-size: 12px">定时 : </span>
        <div class="timeList">
          <el-time-picker
            v-model="startTime"
            size="mini"
            placeholder="开启时间"
            prefix-icon="none"
            :editable="false"
            ref="startTime"
            @change="timeChange($event, 'startTime')"
          >
          </el-time-picker>
          <el-time-picker
            v-model="endTime"
            size="mini"
            :editable="false"
            prefix-icon="none"
            placeholder="关闭时间"
            :disabled-minutes="disabledMinutes"
            ref="endTime"
            @change="timeChange($event, 'endTime')"
          >
          </el-time-picker>
          <div class="timeList2">
            <div @click.stop="tiemSelect('startTime')">
              <span
                v-show="startTime"
                @click.stop="tiemClear('startTime')"
                class="el-input__icon el-icon-circle-close"
              ></span>
            </div>
            <div @click="tiemSelect('endTime')">
              <span
                v-show="endTime"
                @click.stop="tiemClear('endTime')"
                class="el-input__icon el-icon-circle-close"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="setTemp">
          <div class="setTemp-left">
            <div class="defaultName">
              <span class="defaultName-name">设定温度</span>
              <span class="defaultName-other">SET TEMP</span>
            </div>
            <span class="temp">{{ params.ctemp }}℃</span>
          </div>
          <div class="setTemp-right">
            <span class="iconfont" @click="changeTemp(true)">&#xe6df;</span>
            <span class="iconfont cut" @click="changeTemp(false)"
              >&#xe6df;</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="item-right">
      <div class="row" v-for="(item, index) in list" :key="index">
        <div class="defaultName">
          <span class="defaultName-name">{{ item.name }}</span>
          <span class="defaultName-other">{{ item.otherName }}</span>
        </div>
        <div class="list">
          <div
            class="list-item"
            @click="change(item.type, item2.id)"
            :class="
              (index === 0 && item2.id === params.cmode) ||
              (index === 1 && item2.id === params.cwind)
                ? 'list-item-a'
                : ''
            "
            v-for="(item2, index2) in item.children"
            :key="index2"
          >
            <span class="iconfont list-item-icon" v-html="item2.icon"></span>
            <span class="item-name">{{ item2.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { accControll, accSetting } from '@/api/home'
const makeRange = (start, end) => {
  const result = []
  for (let i = start; i <= end; i++) {
    result.push(i)
  }
  return result
}
export default {
  props: ['itemData', 'openId'],
  data () {
    return {
      checked: true,
      list: [
        {
          name: '模式',
          otherName: 'MODE',
          type: 'cmode',
          children: [
            { id: 1, name: '制冷', icon: '&#xe63b;' },
            { id: 2, name: '制热', icon: '&#xe63c;' },
            { id: 3, name: '除湿', icon: '&#xe603;' }
            // { id: 4, name: '送风', icon: '&#xe639;' }
          ]
        },
        {
          name: '风速',
          otherName: 'FAN',
          type: 'cwind',
          children: [
            { id: 3, name: '高速', icon: '&#xe63b;' },
            { id: 2, name: '中速', icon: '&#xe63c;' },
            { id: 1, name: '低速', icon: '&#xe603;' }
          ]
        }
      ],
      params: {
        airid: '标识',
        conoff: '0:开，1:关',
        cmode: '0:自动,1:制冷,2:除湿,3:送风,4:制热',
        ctemp: '温度16-30',
        cwind: '风速 0=自动,1:低,2:中,3:高',
        cwinddir: '0'
      },
      params2: {},
      name: '',
      startTime: '',
      endTime: ''
    }
  },
  watch: {
    itemData (itemData) {
      console.log(itemData)
      var { airid, cmode, ctemp, cwind, startTime, endTime } = itemData
      this.params = { airid, cmode, ctemp, cwind, cwinddir: '0', conoff: '0' }
      this.params.openId = this.openId
      if (endTime) {
        this.endTime = new Date(endTime)
      }
      if (startTime) {
        this.startTime = new Date(startTime)
      }
      console.log(this.startTime)
      if (this.itemData.state === 1) {
        this.checked = false
        this.params.conoff = '1'
      }
      this.params2 = JSON.parse(JSON.stringify(this.params))
    }
  },
  mounted () {
    console.log(this.itemData)
    this.name = this.itemData.name
    var { airid, cmode, ctemp, cwind, startTime, endTime } = this.itemData
    this.params = { airid, cmode, ctemp, cwind, cwinddir: '0', conoff: '0' }
    this.params.openId = this.openId
    if (endTime) {
      this.endTime = new Date(endTime)
    }
    if (startTime) {
      this.startTime = new Date(startTime)
    }
    if (this.itemData.state === 1) {
      this.checked = false
      this.params.conoff = '1'
    }
    this.params2 = JSON.parse(JSON.stringify(this.params))
  },
  methods: {
    disabledMinutes () {
      var Minutes = new Date().getMinutes()
      if (this.startTime) {
        Minutes = new Date(this.startTime).getMinutes()
      }
      return makeRange(0, Minutes)
    },
    tiemSelect (type) {
      this.$refs[type].focus()
    },
    timeChange (value, type, isClear) {
      var timeStr = new Date(value).getTime()
      var params = {
        airid: this.params2.airid,
        startTime: this.startTime ? new Date(this.startTime).getTime() : '',
        endTime: this.endTime ? new Date(this.endTime).getTime() : ''
      }
      if (type === 'endTime') {
        params.endTime = timeStr
      } else {
        params.startTime = timeStr
      }
      params.openId = this.openId
      accSetting(params).then((res) => {
        if (Number(res.code) === 200) {
          this.$message.success('设置成功')
          if (isClear) {
            this.$data[type] = ''
          }
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    tiemClear (type) {
      this.timeChange('', type, true)
    },
    accControll () {
      accControll(this.params2).then((res) => {
        if (Number(res.code) === 200) {
          this.params = JSON.parse(JSON.stringify(this.params2))
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 开关
    switchChange (checked) {
      this.params2.conoff = checked ? '0' : '1'
      this.accControll()
    },
    // 模式
    change (type, id) {
      if (id === this.params[type]) {
        this.params2[type] = '0'
      } else {
        this.params2[type] = id
      }
      this.accControll()
    },
    // 温度
    changeTemp (add) {
      if (add) {
        this.params2.ctemp++
      } else {
        this.params2.ctemp--
        if (this.params2.ctemp < 0) {
          this.params2.ctemp = 0
        }
      }
      this.accControll()
    }
  }
}
</script>
<style lang='scss' scoped>
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 80px;
}
/deep/ .el-input__inner {
  padding-left: 6px !important;
  padding-right: 6px !important;
  height: 22px !important;
}
.row {
  display: flex;
  width: 100%;
  align-items: center;
  .timeList {
    margin-left: 4px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .timeList2 {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;

      div {
        width: 50%;
        height: 100%;
        position: relative;
        .el-icon-circle-close {
          position: absolute;
          z-index: 9999;
          right: 0px;
          top: 4px;
          font-size: 12px;
          line-height: 22px;
        }
      }
    }
  }
}
.defaultName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #171719;
  font-size: 14px;
  min-width: 40px;
  .defaultName-other {
    font-weight: 400;
    font-size: 12px;
  }
}
.switch-box-item {
  height: 200px;
  background: #ffffff;
  // box-shadow: 0px 4px 12px 0px #4776b8;
  border-radius: 16px;
  padding: 14px;
  background-image: url("../assets/images/labsetBg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 0 100%;
  margin: 10px 10px 0;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  overflow: hidden;
  .item-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    padding-right: 12px;
    height: 100%;
    border-right: 1px solid #f0f0f0;

    .left {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background: #eff6ff;
      font-size: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #0474ef;
      margin-right: 44px;
      flex-shrink: 0;
    }

    .right {
      display: flex;
      align-items: center;
      flex-direction: column;

      .name {
        color: #171719;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 8px;
      }
    }

    .setTemp {
      display: flex;
      align-content: center;
      border-radius: 7px;
      border: 1px solid #d3e2ff;
      min-width: 200px;
      height: 74px;
      .setTemp-left {
        display: flex;
        align-content: center;
        justify-content: center;
        background-color: #f3f7ff;
        flex: 1;
        .temp {
          line-height: 74px;
          font-weight: bold;
          color: #171719;
          font-size: 20px;
          margin-left: 14px;
        }
      }
      .setTemp-right {
        border-left: 1px solid #d3e2ff;
        display: flex;
        flex-direction: column;
        width: 44px;
        font-weight: bold;
        font-size: 20px;
        span {
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .cut {
          transform: rotate(180deg);
          border-bottom: 1px solid #d3e2ff;
        }
      }
    }
  }
  .item-right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    padding-left: 16px;
    .list {
      display: flex;
      align-items: center;
      margin-left: 6px;
      flex: 1;
      .list-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 8px;
        .list-item-icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid #e2e6ee;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .item-name {
          font-weight: 400;
          color: #171719;
          margin-top: 6px;
          font-size: 12px;
        }
      }
      .list-item-a {
        .list-item-icon {
          color: #fff;
          background: #2c72f9;
          box-shadow: 0px 3px 6px 0px rgba(44, 114, 249, 0.41);
        }
        .item-name {
          color: #2c72f9;
        }
      }
    }
  }
}

.switch-box-item-close {
  background-color: #cad3df !important;
}
</style>
