<template>
<div id="app-content">

  <router-view />
</div>
</template>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
span,
div {
  -webkit-touch-callout: none; /* iOS Safari */

  -webkit-user-select: none; /* Chrome/Safari/Opera */

  -khtml-user-select: none; /* Konqueror */

  -moz-user-select: none; /* Firefox */

  -ms-user-select: none; /* Internet Explorer/Edge */

  user-select: none; /* Non-prefixed version, currently

not supported by any browser */
}
.el-switch {
  -webkit-tap-highlight-color: transparent; //去除点击背景色
}

//移动端自动横屏

.swiper-container{
  transform: translateZ(0px);
}
.el-overlay{
transform: translateZ(2px);
}
@media screen and (orientation: portrait) {
  //竖屏状态下

 html {
    position: absolute;
    width: 100vh !important;
    height: 100vw !important;
    top: 0;
    left: 100vw;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    transform-origin: 0% 0%;
    transform-style: preserve-3d;
    overflow: hidden;
  }
}
// @media screen and (orientation: landscape) {
//   //横屏状态下
//   html {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100vw;
//     height: 100vh;
//   }
// }
</style>
