
<template>
  <div class="dialog-default">
    <el-dialog
      v-model="dialogVisible"
      width="800px"
      top="170px"
      custom-class="el-dialog-devDetails"
      :show-close="false"
      @close="close"
    >
      <template v-slot:title>
        <div class="top">
          <div class="top-left">
            <span>仪器详情</span>
          </div>
          <span class="iconfont icon-close" @click="dialogVisible = false"
            >&#xe601;</span
          >
        </div>
      </template>
      <div class="info">
        <img :src="item.img" alt="" />
        <div class="info-left">
          <div class="info-left-name">
            <span class="name">{{ item.instruName }}</span>
          </div>
          <div class="main">
            <div class="main-left">
              <div class="info-left-item">
                <span class="key">负责人</span>
                <span class="value">{{ item.realName }}</span>
              </div>
              <div class="info-left-item">
                <span class="key">联系方式</span>
                <span class="value">{{ item.phone }}</span>
              </div>
              <div class="info-left-item">
                <span class="key">组织机构</span>
                <span class="value">{{ item.departmentName }}</span>
              </div>
              <div
                class="info-left-item"
                v-if="item.equipment === 2 || item.equipment === 3"
              >
                <span class="key">{{
                  item.equipment === 3 ? "云盒子" : "仪器猫"
                }}</span>
                <el-switch
                  v-model="checked"
                  :disabled="
                    !item.eStatus || item.eStatus < 1 || item.eStatus > 3
                  "
                  active-color="#00B590"
                  inactive-color="#9AA0AC"
                  :loading="loading"
                  @change="switchChange($event)"
                  :before-change="beforeChange"
                ></el-switch>
              </div>
              <div class="info-left-item">
                <span class="key">仪器状态</span>
                <div
                  class="status"
                  :class="'status-' + estatus(item.eStatus).c"
                >
                  <span class="dian"></span>
                  <span class="text">{{ estatus(item.eStatus).t }}</span>
                </div>
              </div>
              <div class="info-left-item" v-if="!changParam">
                <span class="key">预约次数</span>
                <span class="value">{{ item.orderCount>=10000?'9999+':item.orderCount }}</span>
              </div>
              <div class="info-left-item" v-if="changParam">
                <span class="key">温度</span>
                <span class="value" :style="'color:'+ (bingXiangValue.status?'#00ff9a':'#ea1f6f')">{{ bingXiangValue.value+'℃' }}</span>
              </div>
              <div class="info-left-item">
                <span class="key">使用时长</span>
                <span class="value">{{runTime(item.runTime )}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { switchMachine, switchMachineStatus } from '../api/home'
export default {
  emits: ['devClose'],
  data () {
    return {
      dialogVisible: false,
      wind: '',
      checked: false,
      item: {},
      loading: false,
      cantChange: false,
      timer: null,
      timer2: null,
      changParam: false
    }
  },
  props: ['openId', 'bingXiangValue'],
  methods: {
    // 时间格式转换
    runTime (runTime) {
      let secondTime = parseInt(runTime)// 将传入的秒的值转化为Number
      let min = 0// 初始化分
      let h = 0// 初始化小时
      var result = ''
      if (secondTime > 60) { // 如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60)// 获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60)// 获取秒数，秒数取佘，得到整数秒数
        result = min + '分钟'
        if (min > 60) { // 如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60)// 获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60) // 获取小时后取佘的分，获取分钟除以60取佘的分
          result = h + '小时' + (min > 0 ? min + '分钟' : '')
          if (h >= 10000) {
            result = '9999+小时'
          }
        }
      } else {
        if (secondTime > 0) {
          result = '1分钟'
        } else {
          result = '0分钟'
        }
      }
      return result
    },
    /**
     * 修改仪器猫/云盒子状态
     */
    beforeChange () {
      this.loading = true
      this.cantChange = true
      switchMachine({
        parameter: { instruId: this.item.instruId, flag: !this.checked },
        openId: this.openId
      }).then((res) => {
        if (Number(res.code) === 200) {
          var time = 20
          this.cantChange = true
          this.timer = setInterval(() => {
            time--
            if (time <= 0) {
              clearInterval(this.timer)
              this.cantChange = false
              this.loading = false
            }
          }, 1000)
        } else {
          this.$message({
            message: res.msg || '操作失败',
            type: 'warning'
          })
          this.dialogVisible = false
        }
      })
      return true
    },
    // 获取仪器猫/云盒子状态
    switchMachineStatus () {
      switchMachineStatus({
        parameter: { instruId: this.item.instruId }
      }).then(res => {
        var status = Object.values(res.data)[0]
        setTimeout(() => {
          var checked = false
          if (status === 'ON') {
            checked = true
          }
          if (this.checked === checked || !this.cantChange) {
            this.checked = checked
            clearInterval(this.timer)
            this.cantChange = false
            this.loading = false
          }
        }, 100)
      })
    },
    switchChange (flag) {
      this.loading = true
      this.cantChange = true
    },
    estatus (estatus) {
      // 设备状态(1:运行,2:待机,3:关机,4:离线)
      switch (estatus) {
        case 1:
          return { t: '运行', c: 'g' }

        case 2:
          return { t: '待机', c: '1' }

        case 3:
          return { t: '关机', c: '2' }

        case 4:
          return { t: '离线', c: '3' }

        default:
          return { t: '离线', c: '3' }
      }
    },
    confirm () {
      this.dialogVisible = false
    },
    show (item, changParam) {
      this.item = item
      this.changParam = changParam
      this.dialogVisible = true
      this.switchMachineStatus()
      // this.timer2 = setInterval(() => {
      //   this.switchMachineStatus()
      // }, 10000)
    },
    close () {
      this.checked = false
      this.cantChange = false
      clearInterval(this.timer)
      clearInterval(this.timer2)
      this.$emit('devClose')
    }
  }
}
</script>
<style lang='scss' scoped>
/deep/ .el-dialog-devDetails{
  background-image: url('../assets/images/devBg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // border-radius: 70px;
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .top-left {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: rgba(232, 255, 251, 1);
    font-size: 18px;
  }
  .icon-close {
    font-size: 24px;
    color: rgba(232, 255, 251, 0.3);
    padding: 8px;
    position: relative;
    top:-8px;
    right: -8px;
  }
}
.info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  .info-left {
    display: flex;
    flex-direction: column;
    color:rgba(232, 255, 251, 1);
    .main {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .main-left {
        flex: 1;
        margin-top: 20px;
      }
    }
    .info-left-name {
      .name {
        font-size: 20px;
        font-weight: bold;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
    .info-left-item {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      padding: 8px 0;
      .key {
        text-align: right;
        display: inline-block;
        padding-right: 24px;
        width: 130px;
        text-align: left;
      }
      .value {
        text-align: left;
        display: inline-block;
      }
      .value-red {
        color: #e6192f;
      }
      .status {
        display: flex;
        align-items: center;
        color: #3dea1f;
        .dian {
          background: #3dea1f !important;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 6px;
        }
      }
      .status-1 {
        .text {
          color: #44aaff;
        }
        .dian {
          background: #44aaff !important;
        }
      }
      .status-2 {
        .text {
          color: #ea1f6f;
        }
        .dian {
          background: #ea1f6f !important;
        }
      }
      .status-3 {
        .text {
          color: #84889e;
        }
        .dian {
          background: #84889e !important;
        }
      }
    }
  }
  img {
    width: 268px;
    height: 268px;
    border-radius: 8px;
    border: 1px solid #dae6fd;
    margin-right: 48px;
  }
}
</style>
